import React from "react";
import { Link } from "gatsby";
import * as styles from "./Upcoming.module.scss";

const Upcoming = ({ exhibitions }) => {
  var now = new Date();
  const upcoming = exhibitions.filter(({ node }) => {
    const startDate = node.startDate ? new Date(node.startDate) : null;
    const endDate = node.endDate ? new Date(node.endDate) : startDate;
    return startDate > now && endDate > now;
  });

  const current = exhibitions.filter(({ node }) => {
    const startDate = node.startDate ? new Date(node.startDate) : null;
    const endDate = node.endDate ? new Date(node.endDate) : null;
    return startDate < now && endDate > now;
  });

  if (upcoming.length === 0 && current.length === 0) return false;

  return (
    <section className={styles.upcomming}>
      {current.length > 0 && (
        <>
          <h2>
            <span>Current exhibitions:</span>
          </h2>
          <ul>
            {current.map(({ node }, index) => {
              const date = node.startDate ? new Date(node.startDate) : null;
              const dateString = date
                ? date.toLocaleString("default", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                : null;
              return (
                <li key={index}>
                  <h3>
                    <span>
                      <Link to={`/biography/${node.slug.current}`}>
                        {node.title || node.shortTitle}
                      </Link>
                    </span>
                  </h3>
                </li>
              );
            })}
          </ul>
        </>
      )}
      {upcoming.length > 0 && (
        <>
          <h2>
            <span>Upcoming exhibitions:</span>
          </h2>
          <ul>
            {upcoming.map(({ node }, index) => {
              const date = node.startDate ? new Date(node.startDate) : null;
              const dateString = date
                ? date.toLocaleString("default", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                : null;
              return (
                <li key={index}>
                  <h3>
                    <span>
                      <Link to={`/biography/${node.slug.current}`}>
                        {node.title || node.shortTitle}
                      </Link>
                    </span>
                  </h3>
                </li>
              );
            })}
          </ul>
        </>
      )}
      <h2 className={styles.links}>
        <span>
          <Link to="/links">Recent links to artworks and events</Link>
        </span>
      </h2>
    </section>
  );
};

export { Upcoming };
