import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/common/seo";
import { HomePage } from "../components/pageLayout/HomePage";

export const biographyPages = graphql`
  query HomePageQuery {
    home: sanityHomepageSingleton {
      title
      artworks {
        ... on SanityImageItem {
          _key
          asset {
            gatsbyImageData
          }
        }
      }
      description {
        _rawChildren
      }
    }
    solo: allSanityBiography(
      sort: { fields: [startDate], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        startDate: { ne: null }
        type: { eq: "solo" }
      }
    ) {
      edges {
        node {
          id
          startDate
          endDate
          type
          mainImage {
            asset {
              id
              gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
            }
          }
          title
          slug {
            current
          }
        }
      }
    }
    group: allSanityBiography(
      sort: { fields: [startDate], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        startDate: { ne: null }
        type: { eq: "group" }
      }
    ) {
      edges {
        node {
          id
          startDate
          endDate
          type
          mainImage {
            asset {
              id
              gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
            }
          }
          title
          shortTitle
          slug {
            current
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const exhibitions = [...data.solo.edges, ...data.group.edges];
  const home = data.home;
  return (
    <>
      <Seo title="Alistair McClymont" />
      <HomePage exhibitions={exhibitions} home={home} />
    </>
  );
};

export default IndexPage;
